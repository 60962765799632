import * as React from 'react';
import { PanelSet } from '@app/models/panel-set';
import { AttributeList } from '@bespohk/uikit';

const Routes = ({ show, panelSet }: { show?: boolean; panelSet: PanelSet }) => {
  if (!show) {
    return null;
  }

  const {
    punchPositions,
    uniqueToolTypes,
    setupTime,
    additionalTime,
    quantity,
    punchPositionsMinutes,
    staticTime,
    plateFinishMinutes,
    verticalPunchPositionExtraTime,
  } = panelSet.panel;

  const wallboxMinutes = panelSet.backplate.routingMinutes;
  const { wallboxAdditionalMinutes, wallboxAdditionalMinutesPerRow } =
    panelSet.backplate;

  const plateFinishRoute = panelSet.plateFinishRoute || 'Unknown';

  const panelSetRoute = panelSet.route || 'Unknown';

  const wallboxRoute = panelSet.wallboxRoute || 'Unknown';

  return (
    <>
      <AttributeList
        items={[
          {
            name: 'Total Horizontal Punch Positions',
            value: punchPositions.horizontal,
          },
          {
            name: 'Total Unique Vertical Punch Positions',
            value: punchPositions.vertical,
          },
          {
            name: 'Unique Tool Types',
            value: panelSet.panel.uniqueToolTypes.length,
          },
          {
            name: 'Total PS Route Allocation',
            value: panelSet.totalPsRouteAllocation,
          },
          {
            name: 'Total Number of Wallboxes',
            value: panelSet.backplate.wallboxes.length,
          },
        ]}
      />
      <AttributeList
        items={[
          {
            name: `Panel Set Route - ${panelSetRoute}`,
            value: `PS Route Allocation = ${panelSet.routingMinutes}`,
          },
          {
            name: `Front Plate Route - ${plateFinishRoute}`,
            value: (
              <>
                <div>
                  Tool Type: (No of tool types - {uniqueToolTypes.length} *{' '}
                  {panelSet.panel.routeSetting('tool_type_minutes')}
                  mins) = {panelSet.panel.toolTypeMinutes}
                </div>
                <div>
                  Punch Positions: (Total No of punch positions (
                  {punchPositions.horizontal} [horizontal] +{' '}
                  {punchPositions.vertical} [vertical]) * {staticTime}min) ={' '}
                  {punchPositionsMinutes}
                </div>
                <div>
                  Forming: Set-up time {setupTime}min / {quantity} qty +
                  additional time {additionalTime}min ={' '}
                  {panelSet.panel.formingMinutes}
                </div>
                <div>
                  Vertical Punch Extra Time: (Vert positions 82T/82P) ={' '}
                  {verticalPunchPositionExtraTime}
                </div>
                <div>
                  Power coat finish: (add {plateFinishMinutes} mins) ={' '}
                  {plateFinishMinutes}
                </div>
                <div>Total: {panelSet.panel.routingMinutes}</div>
              </>
            ),
          },
          {
            name: `Wallbox Route - ${wallboxRoute}`,
            value: `${
              panelSet.backplate.wallboxes.length
            } wallboxes + ${wallboxAdditionalMinutes} additional minutes + ${
              wallboxAdditionalMinutesPerRow * (panelSet.panel.rows.length - 1)
            } additional minutes per row = ${wallboxMinutes}`,
          },
        ]}
      />
    </>
  );
};

export { Routes };
export default Routes;
