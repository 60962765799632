import Engraving from '@app/models/engraving';
import * as React from 'react';
import { TabularData } from '@app/containers';
import { BooleanColumnRenderer } from '@app/components';

const enabledRenderer = (engraving: Engraving): React.ReactNode => (
  <BooleanColumnRenderer valid={engraving.enabled} />
);

const Engravings = () => (
  <TabularData
    type={Engraving}
    endpoint="/engravings"
    identifier="partNumber"
    columns={[
      { key: 'description' },
      { key: 'partNumber', width: '20%' },
      { key: 'enabled', renderer: enabledRenderer },
    ]}
    filter={{
      export: true,
      placeholder: 'Enter a part number to search for...',
      builder: (q: string) => (q ? `(partNumber: ${q}){*}` : undefined),
    }}
  />
);

export { Engravings };
export default Engravings;
