import * as yup from 'yup';

import Model from '@app/models/strapping';
import { Input } from '@bespohk/uikit/components';
import { ModelForm } from '@app/containers';
import * as React from 'react';

type OwnProps = unknown;

const WallboxSubAssembly = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/wallbox-subassemblies"
      type={Model}
      validate={yup.object().shape({
        code: yup.string().required('Code is a required field.'),
        routeAllocation: yup
          .string()
          .required('Route allocation is a required field.'),
      })}
      fieldsets={[
        [
          {
            name: 'code',
            label: 'Part Number',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'routeAllocation',
            renderer: Input,
            props: { required: true, type: 'number' },
          },
        ],
      ]}
    />
  );
};

export { WallboxSubAssembly };
export default WallboxSubAssembly;
