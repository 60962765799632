import { BooleanColumnRenderer } from '@app/components';
import Customer from '@app/models/customer';
import * as React from 'react';
import { TabularData } from '@app/containers';

type OwnProps = unknown;

const enabledRenderer = (customer: Customer): React.ReactNode => (
  <BooleanColumnRenderer valid={customer.enabled} />
);

const Customers = (_: OwnProps) => (
  <TabularData
    type={Customer}
    endpoint="/customers"
    filter={{
      export: true,
      builder: (q: string) => (q ? `(businessName: ${q}){*}` : undefined),
    }}
    columns={[
      { key: 'businessName', label: 'Name' },
      { key: 'accountCode' },
      { key: 'enabled', renderer: enabledRenderer, width: '10%' },
      {
        key: 'humanUpdatedAt',
        label: 'Last Updated',
      },
    ]}
  />
);

export { Customers };
export default Customers;
