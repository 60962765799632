import {
  Operations as BOMOperations,
  operations as bomOperations,
} from '@app/state/ducks/bom/operations';
import { Button, ButtonBar, Heading, Prompt } from '@bespohk/uikit/components';
import {
  Operations as InclusionOperations,
  operations as inclusionOperations,
} from '@app/state/ducks/inclusion/operations';
import {
  Operations as OEFOperations,
  operations as oefOperations,
} from '@app/state/ducks/oef/operations';
import * as React from 'react';

import { InclusionsPrompt } from './inclusions-prompt';
import { Project } from '@app/models/project';
import State from '@app/state';
import styles from './projects.module.css';
import { useOperations } from '@app/helpers/redux';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

type OwnProps = unknown;

const projectSelector = (state: State) => state.project.data;

const Exports = (_: OwnProps) => {
  const route = useRouteMatch();

  const oefOps = useOperations<OEFOperations>(oefOperations);
  const incOps = useOperations<InclusionOperations>(inclusionOperations);
  const bomOps = useOperations<BOMOperations>(bomOperations);

  const [showExportPrompt, setShowExportPrompt] = React.useState(false);
  const [showInclusionPrompt, setShowInclusionPrompt] = React.useState(false);

  const project: Project = useSelector(projectSelector);

  if (!project) {
    return null;
  }

  return (
    <div className={styles.outer}>
      <Heading size="medium">Exports</Heading>
      <ButtonBar layout="left">
        <Button
          style="tertiary"
          label="Inclusions"
          size="small"
          action={() => {
            setShowInclusionPrompt(true);
            incOps.generate(project);
          }}
        />
        {project.hasPanels && (
          <Button
            style="tertiary"
            label="OEF"
            size="small"
            action={() => {
              oefOps.generate(project);
            }}
          />
        )}
        {project.hasPanels && (
          <Button
            style="tertiary"
            label="Export BOM"
            size="small"
            action={() => {
              setShowExportPrompt(true);
            }}
          />
        )}
        {project.hasPanels && (
          <Button
            style="tertiary"
            label="View BOM"
            size="small"
            action={() => {
              bomOps.generate(project);
            }}
          />
        )}
      </ButtonBar>
      <Heading size="small">Drawings</Heading>
      <ButtonBar layout="left">
        <Button
          style="tertiary"
          label="Customer"
          size="small"
          action={() => {
            window.open(`${route.url}/drawings/customer`);
          }}
        />
        <Button
          style="tertiary"
          label="Factory"
          size="small"
          action={() => {
            window.open(`${route.url}/drawings/factory`);
          }}
        />
        <Button
          style="tertiary"
          label="As-Built"
          size="small"
          action={() => {
            window.open(`${route.url}/drawings/construction`);
          }}
        />
      </ButtonBar>
      <Prompt
        title="Export to Pronto?"
        message="Are you sure you'd like to submit this BOM to Pronto?"
        when={showExportPrompt}
        negative={{ label: 'Cancel', action: () => setShowExportPrompt(false) }}
        positive={{
          label: 'OK',
          action: () => {
            setShowExportPrompt(false);
            bomOps.exportToPronto(project);
          },
        }}
      />
      <InclusionsPrompt
        show={showInclusionPrompt}
        cancel={() => setShowInclusionPrompt(false)}
      />
    </div>
  );
};

export { Exports };
export default Exports;
