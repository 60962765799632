import {
  exportError,
  exportRequest,
  exportSuccess,
  generateAttempt,
  generateSuccess,
} from './actions';

import { Dispatch } from 'redux';
import Document from '@app/models/bom';
import { Project } from '@app/models/project';
import { operations as alertOperations } from '../alerts/operations'; // eslint-disable-line
import { download } from '@app/helpers/browser';
import { generate as generateBOM } from '@app/services/bom';
import { post } from '@app/helpers/api';
import { logger } from '@app/helpers/log';

const log = logger('ducks:bom:operations');

const { add } = alertOperations;

const generate =
  (project: Project): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(generateAttempt());

    const bom: Document = generateBOM(project);
    dispatch(generateSuccess(bom));

    log(bom);

    download(bom.toXml(), `${project.name}-bom-${Date.now()}.xml`, 'text/xml');

    return bom;
  };

const exportToPronto =
  (project: Project): any =>
  (dispatch: Dispatch<any>) => {
    const bom: Document = generateBOM(project);

    log(bom);

    dispatch(exportRequest(bom));

    return post(`/projects/${project.uuid}/bom`, bom.toXml())
      .then(() => {
        add(
          'success',
          `Successfully uploaded BOM ${project.name} to Pronto`,
          4,
        )(dispatch);
        dispatch(exportSuccess(bom));
      })
      .catch(() => {
        add(
          'error',
          `Failed to upload BOM ${project.name} to Pronto`,
          4,
        )(dispatch);
        dispatch(exportError(bom));
      });
  };

type Operations = {
  generate: (project: Project) => Document;
  exportToPronto: (project: Project) => void;
};

const operations: Operations = {
  generate,
  exportToPronto,
};

export { operations, Operations };
