import WallboxSubAssembly from '@app/models/wallbox-subassembly';
import * as React from 'react';
import { TabularData } from '@app/containers';

const WallboxSubAssemblies = () => (
  <TabularData
    type={WallboxSubAssembly}
    endpoint="/wallbox-subassemblies"
    identifier="id"
    columns={[{ key: 'code' }, { key: 'routeAllocation', width: '20%' }]}
    filter={{
      export: true,
      placeholder: 'Enter a code to search for...',
      builder: (q: string) => (q ? `(code: ${q}){*}` : undefined),
    }}
  />
);

export { WallboxSubAssemblies };
export default WallboxSubAssemblies;
