class WallboxSubAssembly {
  code: string;
  routeAllocation: number;

  public toString(): string {
    return this.code;
  }
}

export default WallboxSubAssembly;
