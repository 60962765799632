import { hydrate } from '@bespohk/lib';
import PlateFinish from './plate-finish';
import PanelType from './panel-type';

class Route {
  id: number;
  minutes: number;
  code: string;
  routeId: string;
  enabled: boolean;

  @hydrate(PlateFinish)
  plateFinish: PlateFinish;

  @hydrate(PanelType)
  panelType: PanelType;

  public toString(): string {
    return this.code;
  }
}

const findRoute = (
  routes: Route[],
  minutes: number,
  panelType: PanelType,
  plateFinish?: PlateFinish,
  compOrPlate?: 'comp' | 'plate',
) => {
  let matcher = (route: Route) =>
    route.enabled &&
    route.minutes === minutes &&
    route.panelType.id === panelType?.id;

  if (plateFinish?.isStainlessSteel) {
    matcher = (route: Route) =>
      route.enabled &&
      route.minutes === minutes &&
      route.panelType.id === panelType?.id &&
      (!route.plateFinish ||
        (route.plateFinish && !route.plateFinish.isStainlessSteel));
  }

  if (compOrPlate) {
    const _oldMatcher = matcher;
    matcher = (route: Route) =>
      _oldMatcher(route) && route.code.toLowerCase().includes(compOrPlate);
  }

  return routes.find(matcher);
};

export { Route, findRoute };
export default Route;
