class PlateFinish {
  readonly id: number;
  name: string;
  partNumber: string;
  supplierCode: string;

  public toString(): string {
    return this.name;
  }

  public isStainlessSteel(): boolean {
    return this.name.toLowerCase().indexOf('stainless') !== -1;
  }
}

export default PlateFinish;
